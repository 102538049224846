import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCOlAfiX48x3Anvypy1Du-X2FdnPFWoynY",
    authDomain: "dadarmatka-cf7b0.firebaseapp.com",
    projectId: "dadarmatka-cf7b0",
    storageBucket: "dadarmatka-cf7b0.appspot.com",
    messagingSenderId: "401175839986",
    appId: "1:401175839986:web:f15ab88a03a082524e3c6b",
    measurementId: "G-H1X09E7ZY8"
  };
  const firebaseApp = initializeApp(firebaseConfig);
  const analytics = getAnalytics(firebaseApp);
  const auth = getAuth();
  const db = getFirestore();
export {firebaseApp,analytics,auth,db};