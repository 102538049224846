<template>
  
    <router-view/>

</template>

<script>
  export default{
    
  }
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body{
  background: black !important;
  color: white;
}

</style>
