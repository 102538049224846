import { createRouter, createWebHistory } from 'vue-router'
import authenticated from './middlewares/authenticated'
import guest from './middlewares/guest'
import middlewarePipeline from './middlewarePipleline'


const routes = [
  {
    path: "/test",
    component: () => import("../views/frontend/Test.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/frontend/Home.vue"),
  },
  {
    path: "/privacy_policy",
    name: "privacyPolicy",
    component: () => import("../views/frontend/PrivacyPolicy.vue"),
  },
  {
    path: "/morning_panel",
    name: "morningPanel",
    component: () => import("../views/frontend/MorningPanel.vue"),
  },
  {
    path: "/matkamorning.php",
    name: "morningPanelphp",
    component: () => import("../views/frontend/MorningPanel.vue"),
  },
  {
    path: "/night_panel",
    name: "nightPanel",
    component: () => import("../views/frontend/NightPanel.vue"),
  },
  {
    path: "/matkanight.php",
    name: "nightPanelphp",
    component: () => import("../views/frontend/NightPanel.vue"),
  },
  {
    path: "/contact_us",
    name: "contactUs",
    component: () => import("../views/frontend/ContactUs.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/frontend/Login.vue"),
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/admin/dashboard",
    name: "dashboard",
    component: () => import("../views/admin/Dashboard.vue"),
    meta: {
      middleware: [authenticated],
    },
  },
  {
    path: "/admin/guessing_table_weekly",
    name: "guessing_table_weekly",
    component: () => import("../views/admin/GuessingTableWeekly.vue"),
    meta: {
      middleware: [authenticated],
    },
  },

  {
    path: "/admin/extras",
    name: "extras",
    component: () => import("../views/admin/Extras.vue"),
    meta: {
      middleware: [authenticated],
    },
  },
  {
    path: "/admin/guessing_table_daily",
    name: "guessing_table_daily",
    component: () => import("../views/admin/GuessingTableDaily.vue"),
    meta: {
      middleware: [authenticated],
    },
  },
  {
    path: "/admin/morning_panels",
    name: "morning_panels",
    component: () => import("../views/admin/MorningPanels.vue"),
    meta: {
      middleware: [authenticated],
    },
  },
  {
    path: "/admin/night_panels",
    name: "night_panels",
    component: () => import("../views/admin/NightPanels.vue"),
    meta: {
      middleware: [authenticated],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware

  const context = {
    to,
    from,
    next
  }


  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })

})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
