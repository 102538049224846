import { auth } from "@/firebaseInit"

export default function authenticated ({ next}){
    auth.onAuthStateChanged(user => {
        if(!user){
            return next({
               name: 'login'
            })
        }
       
        return next()
      });
    
   }