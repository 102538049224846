import axiosApi from 'axios'
let headers;
if(localStorage.token)
{
    headers = {
    "Accept": "application/json",
    "Authorization": `Bearer ${localStorage.token}`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
  }
}
else{
    headers = {
        "Accept": "application/json",
      }  
}


const axios = axiosApi.create({
  baseURL: process.env.VUE_APP_API,
  headers:headers
});

export default axios;